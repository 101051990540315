import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { Modal } from 'antd';
import {
  deleteDocument,
  fetchDocuments,
  publishDocuments,
  unpublishDocuments,
} from '@redux/documents/actions';
import {
  selectDocument,
  selectDocumentDeleting,
  selectDocumentPublishedStatus,
  selectDocumentPublishing,
} from '@redux/documents/reducers';
import { selectPathwaysByRules } from '@pathways/redux/pathways/reducers';
import { FEATURE_DOCUMENT, FEATURE_VIDEO } from '@constants';
import { usePageActions } from '@hooks';
import EditButton from '@components/EditButton';
import ContentNotFound from '@components/ContentNotFound';
import { Column, ColumnGroup } from '@cards/Card';
import DocumentDetailsCard from '@cards/Documents/Details';
import DocumentContentCard from '@cards/Documents/Content';
import DocumentPathwaysCard from '@pathways/cards/DocumentPathways';
import IconButton from '@components/IconButton';
import PublishingButton from '@components/PublishingButton';
import waitForStoreUpdate from '@utils/waitForStoreUpdate';
import { historyType, matchType } from '../../propTypes';
import useIsContentOwnedByCurrentOrganisation from '@hooks/useIsContentOwnedByCurrentOrganisation';

function DocumentDetails({ documentTranslationKey, history, match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useStore();
  const {
    params: { id },
  } = match;
  const [loading, selectedDocument] = useSelector(selectDocument(id));
  const isPublished = useSelector(selectDocumentPublishedStatus(id));
  const isPublishing = useSelector(selectDocumentPublishing);
  const pathwayByRulesFilter = useCallback(
    rule =>
      (rule.what === FEATURE_DOCUMENT || rule.what === FEATURE_VIDEO) &&
      rule.whatDetail.id === Number(id),
    [id],
  );
  const [, pathways] = useSelector(selectPathwaysByRules(pathwayByRulesFilter));

  useEffect(() => {
    dispatch(fetchDocuments());
  }, [dispatch, id]);

  const { setActions, setTitle } = usePageActions({
    showBack: true,
  });

  const isOwnedContent = useIsContentOwnedByCurrentOrganisation(selectedDocument);

  const PublishingAction = useMemo(() => {
    const publish = () => {
      dispatch(publishDocuments([match.params.id]));
    };
    const unpublish = () => {
      dispatch(unpublishDocuments([match.params.id]));
    };

    return isPublished === undefined ? null : (
      <PublishingButton
        key="toggle-published-status"
        isPublished={isPublished}
        isPublishing={isPublishing}
        publish={publish}
        unpublish={unpublish}
        disabled={!isOwnedContent}
      />
    );
  }, [dispatch, isPublished, isPublishing, match, isOwnedContent]);

  const DeleteBtn = useMemo(
    () => (
      <IconButton
        key="delete-btn"
        icon={faTrashAlt}
        size="large"
        tooltip={t('common:buttons.delete')}
        disabled={!isOwnedContent}
        onClick={() => {
          Modal.confirm({
            content: !!pathways.length
              ? t(`${documentTranslationKey}:DocumentDetail.confirmDeleteWarning`, {
                  count: pathways.length,
                })
              : null,
            title: t(`${documentTranslationKey}:DocumentDetail.confirmDelete`),
            onOk: () => {
              dispatch(deleteDocument(match.params.id, documentTranslationKey));
              return waitForStoreUpdate(store, selectDocumentDeleting);
            },
            onCancel: () => {},
          });
        }}
      />
    ),
    [dispatch, documentTranslationKey, match, store, t, pathways.length, isOwnedContent],
  );

  useEffect(() => {
    setActions([
      PublishingAction,
      DeleteBtn,
      <EditButton
        key="edit-btn"
        onClick={() => history.push(`${match.url}/edit`)}
        disabled={!isOwnedContent}
      />,
    ]);
  }, [PublishingAction, DeleteBtn, history, match, setActions, isOwnedContent]);

  useEffect(() => {
    if (selectedDocument?.name) {
      document.title = selectedDocument.name;
    }
    setTitle(selectedDocument ? selectedDocument.name : '');
  }, [selectedDocument]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!selectedDocument && !loading) {
    return <ContentNotFound />;
  }

  return (
    <>
      <ColumnGroup>
        <Column>
          <DocumentDetailsCard documentId={id} documentTranslationKey={documentTranslationKey} />
          <DocumentPathwaysCard documentId={id} />
        </Column>
        <Column>
          <DocumentContentCard documentId={id} />
        </Column>
      </ColumnGroup>
    </>
  );
}

DocumentDetails.propTypes = {
  documentTranslationKey: string,
  history: historyType.isRequired,
  match: matchType.isRequired,
};

export default DocumentDetails;
