import { combineReducers } from 'redux';
import { IForm } from './types';
import {
  ILoadForms,
  IFormsLoaded,
  IDeleteForms,
  IFormsDeleted,
  IPublishForm,
  IPublishFormSuccess,
  IPublishFormFailed,
  IUnpublishForm,
  IUnpublishFormSuccess,
  IUnpublishFormFailed,
  IFetchVersionFormData,
  IFetchVersionFormDataSuccess,
  IFetchVersionFormDataFailed,
  ICreateForm,
  IFormCreated,
  IUpdateForm,
  IFormUpdated,
  ITemporaryFormsLoaded,
} from './actions';
import { FORM, QUESTIONNAIRE, TASK } from '@utils/contentTypes';
import { IState } from '../reducer';
import { IClearFormsForAppUserResults } from '@redux/appUsers/actions';
import { ILoadContentSuccess } from '@redux/core/actions';

const creating = (state: boolean = false, action: ICreateForm | IFormCreated): boolean => {
  switch (action.type) {
    case 'forms/create':
      return true;
    case 'forms/created':
      return false;
    default:
      return state;
  }
};

const deleting = (state: boolean = false, action: IDeleteForms | IFormsDeleted): boolean => {
  switch (action.type) {
    case 'forms/delete':
      return true;
    case 'forms/deleted':
      return false;
    default:
      return state;
  }
};

const loading = (
  state: boolean = false,
  action: ILoadForms | IFormsLoaded | ITemporaryFormsLoaded,
): boolean => {
  switch (action.type) {
    case 'forms/load':
      return true;
    case 'forms/loaded':
      return false;
    case 'forms/temporary-loaded':
      return false;
    default:
      return state;
  }
};

const publishing = (
  state = false,
  action:
    | IPublishForm
    | IPublishFormSuccess
    | IPublishFormFailed
    | IUnpublishForm
    | IUnpublishFormSuccess
    | IUnpublishFormFailed,
) => {
  switch (action.type) {
    case 'forms/publish':
    case 'forms/unpublish':
      return true;
    case 'forms/publish-success':
    case 'forms/publish-failed':
    case 'forms/unpublish-success':
    case 'forms/unpublish-failed':
      return false;
    default:
      return state;
  }
};

const updating = (state: boolean = false, action: IUpdateForm | IFormUpdated): boolean => {
  switch (action.type) {
    case 'forms/update':
      return true;
    case 'forms/updated':
      return false;
    default:
      return state;
  }
};

const byId = (
  state: { [key: string]: IForm } = {},
  action:
    | IFormsLoaded
    | IFormsDeleted
    | ITemporaryFormsLoaded
    | IClearFormsForAppUserResults
    | IFormCreated
    | IPublishFormSuccess
    | IUnpublishFormSuccess
    | IFormUpdated
    | ILoadContentSuccess,
): { [key: string]: IForm } => {
  switch (action.type) {
    case 'forms/loaded':
    case 'forms/temporary-loaded':
      return action.payload.forms.reduce(
        (formsById, form) => ({ ...formsById, [form.uuid]: form }),
        state,
      );
    case 'forms/deleted':
      return Object.entries(state).reduce(
        (formsById, [formId, form]) =>
          action.payload.ids.includes(formId) ? formsById : { ...formsById, [formId]: form },
        {},
      );
    case 'appUsers/clear-app-user-forms-for-results':
      return Object.entries(state).reduce(
        (newState, [key, form]) =>
          action.payload.formIdList.includes(key) ? { ...newState, [key]: form } : newState,
        {},
      );
    case 'forms/created':
      return { ...state, [action.payload.form.uuid]: action.payload.form };
    case 'forms/publish-success':
    case 'forms/unpublish-success':
      return {
        ...state,
        [action.payload.formId]: {
          ...state[action.payload.formId],
          published: !state[action.payload.formId].published,
        },
      };
    case 'forms/updated':
      return { ...state, [action.payload.updatedForm.uuid]: action.payload.updatedForm };
    case 'core/load-content-success':
      return {
        ...state,
        ...action.payload.forms,
      };
    default:
      return state;
  }
};

const list = (
  state: string[] = [],
  action: IFormsLoaded | IFormsDeleted | IFormCreated,
): string[] => {
  switch (action.type) {
    case 'forms/loaded':
      return action.payload.forms.map(({ uuid }) => uuid);
    case 'forms/deleted':
      return state.filter(formId => !action.payload.ids.includes(formId));
    case 'forms/created':
      return [action.payload.form.uuid, ...state];
    default:
      return state;
  }
};

export interface IVersionFormDataState {
  [key: string]: {
    loading: boolean;
    data: any;
  };
}
const versionFormData = (
  state: IVersionFormDataState = {},
  action: IFetchVersionFormData | IFetchVersionFormDataSuccess | IFetchVersionFormDataFailed,
): IVersionFormDataState => {
  switch (action.type) {
    case 'forms/fetch-version':
      return {
        ...state,
        [`${action.payload.formId}_${action.payload.dataVersion}`]: {
          loading: true,
          data: {},
        },
      };
    case 'forms/fetch-version-success':
      return {
        ...state,
        [`${action.payload.formId}_${action.payload.dataVersion}`]: {
          loading: false,
          data: action.payload.data,
        },
      };
    case 'forms/fetch-version-failed':
      return {
        ...state,
        [`${action.payload.formId}_${action.payload.dataVersion}`]: {
          loading: false,
          data: {},
        },
      };

    default:
      return state;
  }
};

export interface IPermissionsState {
  groupAccess: {
    [key: string]: string[];
  };
  individualAccess: {
    [key: string]: string[];
  };
}

export default combineReducers({
  byId,
  creating,
  deleting,
  list,
  loading,
  publishing,
  updating,
  versionFormData,
});

// selects all forms regardless of type
export const selectForms = (state: IState): [boolean, IForm[]] => [
  state.forms.loading,
  state.forms.list.map(id => state.forms.byId[id]),
];

export const selectForm = (formId: string) => (state: IState): [boolean, IForm?] => [
  state.forms.loading,
  state.forms.byId[formId],
];

export const selectVersionFormData = (formId: string, dataVersion: number) => (state: IState) => [
  state.forms.versionFormData[`${formId}_${dataVersion}`]?.loading ?? false,
  state.forms.versionFormData[`${formId}_${dataVersion}`]?.data ?? undefined,
];

export const selectContentForms = (state: IState): [boolean, IForm[]] => [
  state.forms.loading,
  state.forms.list
    .map(id => state.forms.byId[id])
    .filter(form => form.metadata.type === FORM)
    .sort((a, b) => b.created.localeCompare(a.created)),
];

export const selectContentQuestionnaires = (state: IState): [boolean, IForm[]] => [
  state.forms.loading,
  state.forms.list
    .map(id => state.forms.byId[id])
    .filter(qa => qa.metadata.type === QUESTIONNAIRE)
    .sort((a, b) => b.created.localeCompare(a.created)),
];

export const selectContentTasks = (state: IState): [boolean, IForm[]] => [
  state.forms.loading,
  state.forms.list
    .map(id => state.forms.byId[id])
    .filter(task => task.metadata.type === TASK)
    .sort((a, b) => b.created.localeCompare(a.created)),
];

export const selectFormsByType = (type: typeof FORM | typeof QUESTIONNAIRE | typeof TASK) => (
  state: IState,
) => [
  state.forms.loading,
  Object.values(state.forms.byId).filter(form => form?.metadata.type === type),
];

export const selectFormIdList = (state: IState) => state.forms.list;

export const selectFormCreating = (state: IState): boolean => state.forms.creating;

export const selectFormUpdating = (state: IState): boolean => state.forms.updating;

export const selectFormPublishing = (state: IState): boolean => state.forms.publishing;

export const selectFormDeleting = (state: IState): boolean => state.forms.deleting;
